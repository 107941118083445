<template>
    <BackgroundWave/>
    <BackgroundDiamond/>
    <Logo/>
    <Publicity1/>
    <Publicity2/>
    <Publicity3/>
    <LoginForm/>
    <ZsLogo/>
    <Footer/>
    <GoTop/>
</template>

<script>
    //引入组件
    import BackgroundWave from './BackgroundWave';
    import BackgroundDiamond from './BackgroundDiamond';
    import Logo from './Logo';
    import Publicity1 from './Publicity1.vue';
    import Publicity2 from './Publicity2.vue';
    import Publicity3 from './Publicity3.vue';
    import LoginForm from './LoginForm';
    import ZsLogo from './ZsLogo';
    import GoTop from '../common/GoTop';
    import Footer from './Footer';

    //引入hooks
    import autoControlScale from '@/hooks/autoControlScale.js';

    export default {
      name: 'LoginApp',
      components: {
        BackgroundWave,
        BackgroundDiamond,
        Logo,
        Publicity1,
        Publicity2,
        Publicity3,
        LoginForm,
        ZsLogo,
        Footer,
        GoTop
      }
    }
</script>

<style>
    .container {
        background: linear-gradient(180deg, rgba(29, 105, 248, 1) 0%, rgba(52, 130, 251, 1) 73%, rgba(88, 168, 255, 1) 125%);
    }
</style>
